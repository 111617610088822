<template>
<div class="security full-width full-height overflow-y-scroll">
    <v-container class="mb-6 pa-6" style="max-width: 768px">
        <v-row class="mb-3">
            <v-col cols="12">
                <h3>Security</h3>
            </v-col>
        </v-row>

        <template v-if="!loading">
            <!-- <v-row dense>
                  <v-col cols="12">
                      <p class="x-small--text text-uppercase"><b>What are you signing up for? (select multiple)</b></p>
                      <buttons-field v-model="model.type" :options="options"></buttons-field>
                  </v-col>
              </v-row>

              <template v-if="model.type && model.type.length">
                  <v-row dense>
                      <v-col cols="12">
                          <hr />
                      </v-col>
                  </v-row>

                  <v-row dense>
                      <v-col cols="12" sm="6">
                          <p class="x-small--text text-uppercase"><b>First Name</b></p>
                          <v-text-field ref="firstName" v-model="model.firstName" :disabled="loading" type="text" class="field" dense filled solo flat label="" placeholder="Enter your first name..." required :rules="[rules.required]">
                          </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                          <p class="x-small--text text-uppercase"><b>Last Name</b></p>
                          <v-text-field ref="lastName" v-model="model.lastName" :disabled="loading" type="text" class="field" dense filled solo flat label="" placeholder="Enter your last name..." required :rules="[rules.required]">
                          </v-text-field>
                      </v-col>
                  </v-row>

                  <v-row dense>
                      <v-col cols="12">
                          <p class="x-small--text text-uppercase"><b>Email Address</b></p>
                          <v-text-field ref="email" v-model="model.email" type="email" :disabled="loading" class="field" dense filled solo flat prepend-inner-icon="mdi-email-outline" label="" placeholder="Please enter your email..." required
                            :rules="[rules.required, rules.email]">
                          </v-text-field>
                      </v-col>
                  </v-row>

                  <v-row dense>
                      <v-col cols="12">
                          <p class="x-small--text text-uppercase"><b>Phone Number</b></p>
                          <v-text-field ref="phone" v-model="model.phoneNumber" type="text" :disabled="loading" class="field" dense filled solo flat prepend-inner-icon="mdi-phone-outline" label="" placeholder="Please enter your phone number..."
                            required :rules="[rules.required]">
                          </v-text-field>
                      </v-col>
                  </v-row>

                  <v-row dense>
                      <v-col cols="12">
                          <p class="x-small--text text-uppercase"><b>Postal Address</b></p>
                          <v-text-field ref="address" id="address-input" v-model="model.address" @change="updateAddress" type="text" :disabled="loading" class="field" dense filled solo flat prepend-inner-icon="mdi-map-outline" label=""
                            placeholder="Please enter your postal address..." required :rules="[rules.required]">
                          </v-text-field>
                      </v-col>
                  </v-row>

                  <v-row dense>
                      <v-col cols="12">
                          <p class="x-small--text text-uppercase"><b>Country of Residence</b></p>

                          <v-autocomplete ref="country" v-model="model.country" :disabled="loading" label="" placeholder="Select your country of residence..." :items="countries" item-text="name" item-value="name" class="field" dense filled solo flat>
                              <template v-slot:item="{ item }">
                                  <v-icon class="mr-4">{{item.emoji}}</v-icon>

                                  <v-list-item-content>
                                      <v-list-item-title class="small--text">{{item.name}}</v-list-item-title>
                                  </v-list-item-content>
                              </template>

                              <template v-slot:selection="{ item }">
                                  <v-icon class="mr-4">{{item.emoji}}</v-icon>

                                  <v-list-item-content>
                                      <v-list-item-title class="small--text">{{item.name}}</v-list-item-title>
                                  </v-list-item-content>
                              </template>
                          </v-autocomplete>
                      </v-col>
                  </v-row>

                  <v-row dense>
                      <v-col cols="12">
                          <hr />
                      </v-col>
                  </v-row>

                  <v-row class="mb-6" dense v-if="hasKey('print')">
                      <v-col cols="12">
                          <p class="x-small--text text-uppercase"><b>Do you qualify for the:</b></p>

                          <v-checkbox v-model="model.studentAward" hide-details>
                              <template v-slot:label>
                                  <span class="small--text">Student (Tertiary) Award</span>
                              </template>
                          </v-checkbox>

                          <v-checkbox v-model="model.masterAward" hide-details>
                              <template v-slot:label>
                                  <span class="small--text">Masters Award</span>
                              </template>
                          </v-checkbox>

                          <v-checkbox v-model="model.grandMasterAward" hide-details>
                              <template v-slot:label>
                                  <span class="small--text">Grand Masters Award</span>
                              </template>
                          </v-checkbox>
                      </v-col>

                      <v-col class="mt-3" cols="12">
                          <a class="primary--text small--text" href="https://www.iypsoftware.com" target="_blank">
                              <v-icon class="mr-2" color="primary" small>mdi-open-in-new</v-icon>What is this?
                          </a>
                      </v-col>
                  </v-row>

                  <v-row dense>
                      <v-col cols="12">
                          <p class="x-small--text text-uppercase"><b>Are you an:</b></p>

                          <v-radio-group hide-details v-model="model.entrant">
                              <v-radio value="Australian Entrant">
                                  <template v-slot:label>
                                      <span class="small--text">Australian Entrant</span>
                                  </template>
                              </v-radio>

                              <v-radio value="International Entrant">
                                  <template v-slot:label>
                                      <span class="small--text">International Entrant</span>
                                  </template>
                              </v-radio>
                          </v-radio-group>
                      </v-col>
                  </v-row>
              </template>

              <v-row>
                  <v-col cols="12">
                      <v-btn color="primary" :loading="saving" :disabled="!isValid" @click="save" small>Save</v-btn>
                  </v-col>
              </v-row> -->
        </template>
    </v-container>
</div>
</template>

<script>
// Services
// import ServiceName from '@/services/service-name';

// Components
// import ComponentName from '@/components/component-name';

// Packages
// import _ from 'lodash';
// import moment from 'moment';

export default {
    name: 'security',

    meta: {
        title: 'Security'
    },

    props: {},

    components: {},

    data() {
        return {};
    },

    computed: {},

    methods: {},

    async mounted() {}
}
</script>

<style lang="scss">
.security {}
</style>
